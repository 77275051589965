import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Container, 
  Section,
  Box, 
  Subhead, 
  Text, 
  Space } from "../components/ui"
import { textLeft } from "../tailor.module.css"


function ContentSection(props) {
  return (
    <Box center>
      <Space size={3} />
      <Subhead>{props.heading}</Subhead>
      <Text className={textLeft} >{props.text}</Text>
    </Box>
  )
}

const NewPage = ({ data }) => {

  // contains high-level info for react helmet
  const thisPage = data.allSitePage.edges[0].node.pageContext.content[0]

  // extracts content of interest
  const thisContent = thisPage.inhalt[0]
  
  return (
    <Layout {...thisPage}>
      <Section>
        <Container width="normal">
          <Box center >
            <h1>{thisContent.purpose}</h1>
            <Text as="p" variant="body" className={textLeft} >{thisContent.intro}</Text>

            {thisContent.sections.map((feature, i) => (
                <ContentSection key={i} {...feature} />
              ))}
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq:$path } }) {
      edges {
        node {
          pageContext
        }
      }
    }

  }
`
export default NewPage